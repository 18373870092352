<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :items="obCollection.getModelList()"
          :options="pagination"
          :total="serverItemsLength"
          :last-page="serverLastPage"
          :loading="bLocalLoading"
          btn-action-path="customers"
          btn-action-item-key-id="id"
          hide-btn-action-view
          is-route-name
          @delete="deleteItem"
          @update:sort-by="onSortBy"
          @update:sort-desc="onSortDesc"
          @update:options="onPagination"
          @update:page="onPage"
        >
          <template #[`body.prepend`]="{ headers }">
            <customer-filters-row :tr-headers="headers" />
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <active-icon :active="item.active" />
          </template>

          <template #[`item.name`]="{ item }">
            <v-btn
              :to="{ name: 'customers.update', params: { id: item.id } }"
              text
              color="primary"
            >
              {{ item.firm.name }}
            </v-btn>
          </template>

          <template #[`item.email`]="{ item }">
            {{ item.firm.email }}
          </template>

          <template #[`item.phone`]="{ item }">
            {{ item.firm.phone }}
          </template>

          <template #[`item.doc_type`]="{ item }">
            {{ $t(`doctype.${item.firm.doc_type}`) }}
          </template>

          <template #[`item.type`]="{ item }">
            {{ $t(getTypeLabel(item.type)) }}
          </template>

          <template #[`item.doc_id`]="{ item }">
            <doc-id-viewer
              v-if="item.firm.doc_id"
              :type="item.firm.doc_type"
              :value="item.firm.doc_id"
            />
          </template>

          <template #[`item.actions`]="{ item }">
            <module-actions :value="item.id" @delete="deleteItem" />
          </template>
        </data-table>
      </v-col>
    </v-row>

    <customers-form
      v-model="obItem"
      :open.sync="displayForm"
      :key="`form-${$route.params.id}`"
    />

    <customer-preview-dialog
      :value="obItem"
      :open.sync="previewItem"
      :key="`view-${$route.params.id}`"
    />
  </v-col>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CustomersMixin from "@/modules/customers/mixins/CustomersMixin";
import { DataTableHeader } from "vuetify";

import ActiveIcon from "@/components/common/ActiveIcon.vue";
import CustomersForm from "@/modules/customers/components/Form.vue";
import RutFormDialog from "@/modules/companies/components/RutFormDialog.vue";
import DocIdViewer from "@/components/common/DocIdViewer.vue";
import CustomerPreviewDialog from "@/modules/customers/components/CustomerPreviewDialog.vue";
import { Customer, Firm, FirmData } from "@planetadeleste/vue-mc-gw";
import Utils from "@/services/Utils";
import { set } from "lodash";
import CustomerFiltersRow from "@/modules/customers/components/CustomerFiltersRow.vue";

@Component({
  components: {
    CustomerFiltersRow,
    ActiveIcon,
    CustomersForm,
    RutFormDialog,
    DocIdViewer,
    CustomerPreviewDialog,
  },
})
export default class CustomersList extends Mixins(CustomersMixin) {
  isList = true;

  async getCode(obItem: Customer) {
    if (obItem.id || (obItem.firm && obItem.firm.code)) {
      return;
    }

    const obFirmData: Partial<FirmData> | Firm = obItem.get("firm", {});
    const obResponse = await Utils.nextCode("customer");
    if (obFirmData instanceof Firm) {
      obFirmData.set("code", obResponse.data);
    } else {
      set(obFirmData, "code", obResponse.data);
    }

    obItem.set("firm", obFirmData);
  }

  onMounted() {
    const arHeaders: DataTableHeader[] = [
      { text: "type", value: "type" },
      { text: "email", value: "email" },
      { text: "phone", value: "phone" },
      { text: "doctype", value: "doc_type" },
      { text: "docid", value: "doc_id" },
    ];
    this.addDTHeaders(arHeaders);
    this.hideActionsHeader = !this.canDelete;

    this.index();
  }

  onRegisterEvents() {
    this.addEvent("app.company.selected", this.index);
    this.addEvent("before.show.form", this.getCode);
  }

  getTypeLabel(sValue: string | number) {
    switch (Number(sValue)) {
      case 1:
        return "customer";

      case 2:
        return "provider";

      case 3:
        return "both";
    }

    return "unknown";
  }
}
</script>
